<template>
  <div>
    <Header></Header>
    <PanelMenu></PanelMenu>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="spinner-wrap" v-if="userDetails == null">
              <b-spinner variant="light" class="mr-2" medium></b-spinner>
              <div class="text">Loading please wait...</div>
            </div>
            <div class="profile-sidebar" v-if="userDetails != null">
              <div class="avatar">
                <img src="../../assets/avatarr.png" alt="" />
              </div>
              <strong>{{ userDetails.billing_address.fullname }}</strong>
              <div class="balance">
                Balance: <b>{{ userDetails.balance | toCurrency }}</b>
              </div>
              <ul>
                <li>
                  <b>Email Verified:</b>
                  <span v-if="userDetails.email_verified" class="verified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Verified</span
                  >
                  <span v-else class="unverified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Unverified</span
                  >
                </li>
                <li>
                  <b>Phone Verified:</b>
                  <span v-if="userDetails.phone_verified" class="verified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Verified</span
                  >
                  <span v-else class="unverified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Unverified</span
                  >
                </li>
                <li>
                  <b>TOTP Enabled:</b>
                  <span v-if="userDetails.totp_enabled" class="verified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Enabled</span
                  >
                  <span v-else class="unverified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Disabled</span
                  >
                </li>
                <li>
                  <b>KYC Verified:</b>
                  <span v-if="userDetails.kyc_verified" class="verified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Verified</span
                  >
                  <span v-else class="unverified"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                      />
                    </svg>
                    Unverified</span
                  >
                </li>
                <!-- <li>
                  <b>Plan Remaining Shipments:</b>
                  <span>{{ userDetails.plan_remaining_shipments }} shipments</span>
                </li>
                <li>
                  <b>Plan Expiration:</b>
                  <span>{{ userDetails.plan_expiration | formatDateWithHours }}</span>
                </li> -->
              </ul>
              <button
                class="main-btn d-flex align-items-center justify-content-center"
                style="border-radius: 50px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-box-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                  />
                </svg>
                &nbsp;&nbsp;Log out
              </button>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="panel-page-menu mt-0 mb-3">
              <div class="menu-buttons">
                <button @click="activeTab = 1" :class="activeTab == 1 ? 'active' : ''">
                  Profile Details
                </button>
                <button @click="activeTab = 2" :class="activeTab == 2 ? 'active' : ''">
                  KYC
                </button>
                <button @click="activeTab = 3" :class="activeTab == 3 ? 'active' : ''">
                  TOTP
                </button>
                <button @click="activeTab = 4" :class="activeTab == 4 ? 'active' : ''">
                  Change Password
                </button>
                <button @click="activeTab = 5" :class="activeTab == 5 ? 'active' : ''">
                  API Keys
                </button>
              </div>
            </div>

            <div class="panel-table" v-if="activeTab == 1">
              <div class="head">
                <h3>Profile Details</h3>
                <div class="d-flex"></div>
              </div>
              <div class="content">
                <div class="spinner-wrap" v-if="userDetails == null">
                  <b-spinner variant="light" class="mr-2" medium></b-spinner>
                  <div class="text">Loading please wait...</div>
                </div>
                <ul v-if="userDetails != null">
                  <div class="row">
                    <div class="col-lg-4">
                      <li>
                        <strong>Fullname:</strong>
                        <span>{{ userDetails.billing_address.fullname }}</span>
                      </li>
                    </div>
                    <div class="col-lg-4">
                      <li>
                        <strong>Email:</strong>
                        <span>{{ userDetails.email }}</span>
                      </li>
                    </div>
                    <div class="col-lg-4">
                      <li>
                        <strong>Plan Expiration:</strong>
                        <span v-if="userDetails.plan_expiration">{{
                          userDetails.plan_expiration | formatDate
                        }}</span>
                        <span v-else>No Plan</span>
                      </li>
                    </div>
                    <div class="col-lg-6">
                      <li>
                        <strong>Billing Address:</strong>
                        <span
                          >{{ userDetails.billing_address.line1 }}
                          {{ userDetails.billing_address.line2 }},
                          {{ userDetails.billing_address.state }},
                          {{ userDetails.billing_address.city }} /
                          {{ userDetails.billing_address.country }},
                          {{ userDetails.billing_address.zip }}</span
                        >
                      </li>
                    </div>
                    <div class="col-lg-6">
                      <li>
                        <strong>Created At:</strong>
                        <span>{{ userDetails.created_at | formatDate }}</span>
                      </li>
                    </div>
                    <div class="col-lg-12" v-if="userDetails.plan">
                      <li>
                        <strong>Plan:</strong>
                        <span><b>Name: </b>{{ userDetails.plan.name }}</span>
                        <span
                          ><b>Price: </b>{{ userDetails.plan.price | toCurrency }}</span
                        >
                        <span
                          ><b>Max Shipmets: </b>
                          {{ userDetails.plan.max_shipments }}</span
                        >
                        <span
                          ><b>Remaining Shipmets: </b>
                          {{ userDetails.plan_remaining_shipments }}</span
                        >
                      </li>
                    </div>
                    <div class="col-lg-12">
                      <li>
                        <strong>You dont have any active plan!</strong>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <div class="panel-table" v-if="activeTab == 2">
              <div class="head">
                <h3>KYC Verification</h3>
              </div>
              <div class="content" v-if="kyc_upload_required">
                <form @submit.prevent="submitKyc()">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="v-form-item">
                        <span>Country</span>
                        <country-select
                          v-model="kycData.document_country"
                          :country="kycData.document_country"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="v-form-item">
                        <span>Document Type</span>
                        <select required v-model="kycData.document_type">
                          <option selected disabled>Choose File Type</option>
                          <option value="driverlicense">Driver License</option>
                          <option value="idcard">ID Card</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="v-form-item">
                        <span>Upload Documents</span>
                        <vue-dropzone
                          ref="myVueDropzone1"
                          id="dropzone_one"
                          style="height: 10px"
                          :options="options"
                          required
                          v-on:vdropzone-success="sendingFile"
                        ></vue-dropzone>
                      </div>
                    </div>
                    <div class="col-12">
                      <button type="submit" class="btn btn-primary btn-lg btn-block">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="content" v-if="!kyc_upload_required">
                <h4 class="text-white">KYC verification is completed.</h4>
              </div>
            </div>

            <div class="panel-table" v-if="activeTab == 3">
              <div class="content border-0">
                <div class="totp-wrap" v-if="!userDetails.totp_enabled">
                  <strong>TOTP is <b class="notenabled">Not Enabled</b></strong>
                  <p>Scan QR code below to enable TOTP.</p>
                  <div class="qr-code-wrap">
                    <qr-code :text="qr_value" error-level="L"></qr-code>
                    <span
                      class="security__button mt-3"
                      style="padding: 0; line-height: 1.3"
                      >Scan QR Code from Authenticator App</span
                    >
                  </div>
                  <p>
                    <b style="min-width: 0" class="mb-3">Secret Key: </b> {{ qr_secret }}
                  </p>
                  <div class="v-form-item">
                    <span>Account Password</span>
                    <input
                      type="password"
                      placeholder="Enter your password"
                      v-model="totp_enable_password"
                    />
                  </div>
                  <div class="v-form-item">
                    <span>TOTP Code</span>
                    <input
                      type="text"
                      placeholder="Enter totp code"
                      v-model="totp_enable_code"
                    />
                    <button
                      class="btn btn-primary btn-lg mt-5 btn-block"
                      @click="enableTotp()"
                    >
                      Enable
                    </button>
                  </div>
                </div>

                <div class="totp-wrap" v-if="userDetails.totp_enabled">
                  <strong>TOTP is <b>Enabled</b></strong>
                  <p>
                    If you want to turn off 2FA, input your account password and the
                    six-digit code provided by the Google Authenticator app below.
                  </p>
                  <div class="v-form-item mt-5">
                    <span>Account Password</span>
                    <input
                      type="password"
                      placeholder="Enter your password"
                      v-model="totp_enable_password"
                    />
                  </div>
                  <div class="v-form-item">
                    <span>TOTP Code</span>
                    <input
                      type="text"
                      placeholder="Enter totp code"
                      v-model="totp_enable_code"
                    />
                    <button
                      class="btn btn-primary btn-lg mt-5 btn-block"
                      @click="disableTotp()"
                    >
                      Disable
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="panel-table" v-if="activeTab == 4">
              <div class="head">
                <h3>Change Password</h3>
              </div>
              <div class="content">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="v-form-item">
                      <span>Old Password</span>
                      <input
                        type="password"
                        placeholder="Old password"
                        v-model="passwordForm.old_password"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="v-form-item">
                      <span>New Password</span>
                      <input
                        type="password"
                        placeholder="New password"
                        v-model="passwordForm.new_password"
                      />
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <button class="btn btn-primary" @click="changePassword()">
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="panel-table" v-if="activeTab == 5">
              <div class="head">
                <h3>API Keys</h3>
              </div>
              <div class="content">
                <h4 class="text-white" v-if="!userDetails.totp_enabled">
                  TOTP must be enabled to get an API key.
                </h4>
                <div v-if="userDetails.totp_enabled">
                  <div class="v-form-item">
                    <span>Account Password</span>
                    <input
                      type="password"
                      v-model="api_key_password"
                      placeholder="Account password"
                    />
                  </div>
                  <div class="v-form-item">
                    <span>TOTP Code</span>
                    <input
                      type="password"
                      v-model="api_key_totp"
                      placeholder="TOTP code"
                    />
                  </div>
                  <button class="btn btn-primary" @click="createApiKey()">
                    Create API Key
                  </button>

                  <div class="created-api-key" v-if="api_key">
                    <strong>{{ api_key }}</strong>
                    <copy-to-clipboard :text="api_key" @copy="handleCopy">
                      <button class="btn btn-primary">Copy API Key</button>
                    </copy-to-clipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
import Header from "../../layouts/components/HeaderComp.vue";
import Footer from "../../layouts/components/FooterComp.vue";
import PanelMenu from "../../layouts/components/PanelMenu.vue";
import VueQrcode from "vue-qrcode";
import QrcodeVue from "qrcode.vue";
import CopyToClipboard from "vue-copy-to-clipboard";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  components: {
    BAlert,
    BSpinner,
    Header,
    Footer,
    PanelMenu,
    VueQrcode,
    QrcodeVue,
    vueDropzone: vue2Dropzone,
    CopyToClipboard,
  },
  data() {
    return {
      activeTab: 1,
      errors: [],
      orders: [],
      userDetails: null,
      isUserDetailsLoading: false,
      isLoading: false,
      passwordForm: {
        old_password: "",
        new_password: "",
      },

      qr_value: "",
      qr_secret: "",

      totp_enable_code: "",
      totp_enable_password: "",

      api_key_password: "",
      api_key_totp: "",

      api_key: "",

      kyc_upload_required: null,

      kycData: {
        document_country: "",
        document_type: "",
        files: [],
      },

      options: {
        maxFilesize: 10,
        maxFiles: 3,
        autoprocessqueue: false,
        url: "https://httpbin.org/post",
        previewscontainer: false,
        acceptedFiles: "image/png",
        addRemoveLinks: true,
        thumbnailWidth: 100,
        thumbnailHeight: 80,
      },
    };
  },
  mounted() {
    this.getUserDetails();
    this.getKycRequirements();
  },
  methods: {
    getUserDetails() {
      this.isUserDetailsLoading = true;
      axios
        .get(`user`)
        .then((res) => {
          console.log(res.data);
          this.userDetails = res.data;
          this.isUserDetailsLoading = false;

          if (!res.data.totp_enabled) {
            this.generateOtp();
          }
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    changePassword() {
      axios
        .post(`auth/change-password`, this.passwordForm)
        .then((res) => {
          console.log(res.data);
          this.$toast.open({
            message: res.data.message,
            type: "success",
            position: "top-right",
          });
          if (res.data.code == "OLD_PASSWORD_INVALID") {
            this.$toast.open({
              message: res.data.message,
              type: "error",
              position: "top-right",
            });
          }
          localStorage.setItem("labelhub_access_token", res.data.access_token);
        })
        .catch((err) => {
          if (err.code == "OLD_PASSWORD_INVALID") {
            this.$toast.open({
              message: err.message,
              type: "error",
              position: "top-right",
            });
          }
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
          this.errors.push(err);
        });
    },

    generateOtp() {
      axios
        .get(`auth/totp`)
        .then((res) => {
          console.log(res.data);
          this.qr_value = res.data.qrcode.toString();
          this.qr_secret = res.data.secret;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    enableTotp() {
      let formData = {
        password: this.totp_enable_password,
        totp: this.totp_enable_code,
        disable: false,
      };

      axios
        .post(`auth/totp`, formData)
        .then((res) => {
          if (res.data.code == "TOTP_ENABLED") {
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "top-right",
            });
          } else {
            this.$toast.open({
              message: res.data.message,
              type: "error",
              position: "top-right",
            });
          }
          this.totp_enable_password = "";
          this.totp_enable_code = "";
          this.getUserDetails();
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    disableTotp() {
      let formData = {
        password: this.totp_enable_password,
        totp: this.totp_enable_code,
        disable: true,
      };

      axios
        .post(`auth/totp`, formData)
        .then((res) => {
          if (res.data.code == "TOTP_DISABLED") {
            this.$toast.open({
              message: res.data.message,
              type: "success",
              position: "top-right",
            });
          } else {
            this.$toast.open({
              message: res.data.message,
              type: "error",
              position: "top-right",
            });
          }
          this.totp_enable_password = "";
          this.totp_enable_code = "";
          this.getUserDetails();
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    createApiKey() {
      let formData = {
        password: this.api_key_password,
        totp: this.api_key_totp,
        ip: null,
      };

      axios
        .post(`auth/create-api-key`, formData)
        .then((res) => {
          this.totp_enable_password = "";
          this.totp_enable_code = "";
          this.api_key = res.data.apikey;
          this.$toast.open({
            message: "API Key created successfully.",
            type: "success",
            position: "top-right",
          });
        })
        .catch((err) => {
          this.errors.push(err);
          this.$toast.open({
            message: "An error occured while creating API Key!",
            type: "error",
            position: "top-right",
          });
        });
    },

    getKycRequirements() {
      axios
        .get(`user/kyc`)
        .then((res) => {
          this.kyc_upload_required = res.data.upload_required;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    sendingFile: function (file) {
      let filee = file.dataURL.split("iVBOR");
      filee = "iVBOR" + filee[1];
      this.kycData.files.push(filee);
      console.log("kyc data ", this.kycData);
    },

    submitKyc() {
      console.log(this.kycData);
      axios
        .post(`user/kyc`, this.kycData)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
          this.errors.push(err);
        });
    },

    handleCopy() {
      this.$toast.open({
        message: "API Key Copied!",
        type: "success",
        position: "top-right",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  background: transparent;
  border: 2px solid #353945 !important;
  border-radius: 10px;
}
.dropzone:hover {
  background: #353945;
}

.country-selector__input {
  border: 2px solid #353945 !important;
}

.input-tel__input[data-v-e59be3b4] {
  border: 2px solid #353945 !important;
}

#MazPhoneNumberInput-27_country_selector {
  border: 1px solid #353945 !important;
}

#dropzone_one {
  letter-spacing: 0.2px;
  color: #fff;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 30px;
  background: transparent;
  border: 2px solid #353945 !important;
}

.dropzone .dz-preview {
  margin: 0 !important;
  min-height: 0px !important;
}

.dropzone .dz-preview {
  margin: 0 !important;
  min-height: 0px !important;
}

#dropzone_one .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#dropzone_one .dz-preview {
  margin-top: 0 !important;
}

#dropzone_one .dz-preview.dz-image-preview {
  background: none !important;
}
</style>
